import { Injectable } from '@angular/core';
import { AuthHttpService } from 'src/app/service/auth-http.service';

@Injectable({
  providedIn: 'root',
})
export class UsersRoleService {
  public roles: JSON;
  public roleMappingArray = [];
  public roleMappingFinalArray = [];
  constructor(private authHttp: AuthHttpService) {}
  getUserRoles() {
    let dataRoles = {};
    if (localStorage.getItem('role_master') == null) {
      this.authHttp.getRoles(dataRoles).subscribe((response) => {
        if (response.success) {
          this.roleMappingArray = response.data;
          localStorage.removeItem('role_master');
          localStorage.setItem(
            'role_master',
            JSON.stringify(this.roleMappingArray)
          );
        }
      });
    } else {
      let data = JSON.parse(localStorage.getItem('role_master'));
      for (let i = 0; i < data.length; i++) {
        this.roleMappingFinalArray[data[i].id] = data[i].display_name;
      }
      this.roles = JSON.parse(localStorage.getItem('role_master'));
    }
  }
}
