<section class="otp_bg" style="overflow: scroll; height: 103vh">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 d-none d-lg-block">
        <div class="left">
          <img
            class="mx-auto d-block pt-5 left_img"
            [nexaeiImageSrc]="'left.png'"
          />
        </div>
      </div>

      <div class="col-md-12 col-lg-6 pt-3">
        <div class="otp-container">
          <span class="logo col-md-12">
            <img class="left_img" [nexaeiImageSrc]="'logo.png'" />
            <h1>Verify your Email Address</h1>
            <p>
              Please type the six digit code sent to your email, if not <br />
              received
              <span
                class="text-green"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                style="cursor: pointer"
              >
                click here
              </span>
            </p>
            <!-- Modal -->
            <div
              class="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <app-otp-to-mobile></app-otp-to-mobile>
            </div>
          </span>

          <form
            class="mt-3"
            [formGroup]="registerFormTwo"
            (ngSubmit)="onSubmit()"
          >
            <fieldset class="form_card g-3 row">
              <div class="col-md-12 d-flex mb-2">
                <div class="tooltiptext-div" *ngIf="showAlert">
                  Copy paste not allowed
                </div>
                <input
                  class="form-control-lg w-14 bg-black border-0 me-2"
                  [ngClass]="{
                    error_input:
                      registerFormTwoControl.digitOne?.dirty &&
                      registerFormTwoControl.digitOne?.invalid
                  }"
                  [ngClass]="{
                    error_input: hasError
                  }"
                  type="text"
                  placeholder=""
                  #otpInputs
                  [formControlName]="'digitOne'"
                  maxlength="1"
                  (keyup)="toggleFocus($event, 0)"
                  (paste)="onPaste($event)"
                />

                <input
                  class="form-control-lg w-14 bg-black border-0 me-2"
                  [ngClass]="{
                    error_input:
                      registerFormTwoControl.digitTwo?.dirty &&
                      registerFormTwoControl.digitTwo?.invalid
                  }"
                  [ngClass]="{
                    error_input: hasError
                  }"
                  type="text"
                  placeholder=""
                  #otpInputs
                  [formControlName]="'digitTwo'"
                  maxlength="1"
                  (keyup)="toggleFocus($event, 1)"
                  (paste)="onPaste($event)"
                />

                <input
                  class="form-control-lg w-14 bg-black border-0 me-2"
                  [ngClass]="{
                    error_input:
                      registerFormTwoControl.digitThree?.dirty &&
                      registerFormTwoControl.digitThree?.invalid
                  }"
                  [ngClass]="{
                    error_input: hasError
                  }"
                  type="text"
                  placeholder=""
                  #otpInputs
                  [formControlName]="'digitThree'"
                  maxlength="1"
                  (keyup)="toggleFocus($event, 2)"
                  (paste)="onPaste($event)"
                />

                <input
                  class="form-control-lg w-14 bg-black border-0 me-2"
                  [ngClass]="{
                    error_input:
                      registerFormTwoControl.digitFour?.dirty &&
                      registerFormTwoControl.digitFour?.invalid
                  }"
                  [ngClass]="{
                    error_input: hasError
                  }"
                  type="text"
                  placeholder=""
                  #otpInputs
                  [formControlName]="'digitFour'"
                  maxlength="1"
                  (keyup)="toggleFocus($event, 3)"
                  (paste)="onPaste($event)"
                />

                <input
                  class="form-control-lg w-14 bg-black border-0 me-2"
                  [ngClass]="{
                    error_input:
                      registerFormTwoControl.digitFive?.dirty &&
                      registerFormTwoControl.digitFive?.invalid
                  }"
                  [ngClass]="{
                    error_input: hasError
                  }"
                  type="text"
                  placeholder=""
                  #otpInputs
                  [formControlName]="'digitFive'"
                  maxlength="1"
                  (keyup)="toggleFocus($event, 4)"
                  (paste)="onPaste($event)"
                />

                <input
                  [ngClass]="{
                    error_input:
                      registerFormTwoControl.digitSix?.dirty &&
                      registerFormTwoControl.digitSix?.invalid
                  }"
                  [ngClass]="{
                    error_input: hasError
                  }"
                  class="form-control-lg w-14 bg-black border-0"
                  type="text"
                  placeholder=""
                  #otpInputs
                  [formControlName]="'digitSix'"
                  maxlength="1"
                  (keyup)="toggleFocus($event, 5)"
                  (paste)="onPaste($event)"
                />
              </div>

              <p
                class="text-center text-red pb-0 mb-1"
                *ngIf="
                  (registerFormTwoControl.digitThree?.dirty &&
                    registerFormTwoControl.digitThree?.invalid) ||
                  (registerFormTwoControl.digitTwo?.dirty &&
                    registerFormTwoControl.digitTwo?.invalid) ||
                  (registerFormTwoControl.digitOne?.dirty &&
                    registerFormTwoControl.digitOne?.invalid) ||
                  (registerFormTwoControl.digitFour?.dirty &&
                    registerFormTwoControl.digitFour?.invalid) ||
                  (registerFormTwoControl.digitFive?.dirty &&
                    registerFormTwoControl.digitFive?.invalid) ||
                  (registerFormTwoControl.digitSix?.dirty &&
                    registerFormTwoControl.digitSix?.invalid)
                "
              >
                Please enter valid OTP
              </p>
              <p
                class="text-center text-red mb-1 pb-0 not-vadid"
                *ngIf="
                  registerFormTwoControl.digitThree?.dirty &&
                  !registerFormTwoControl.digitThree?.invalid &&
                  registerFormTwoControl.digitSix?.dirty &&
                  !registerFormTwoControl.digitSix?.invalid &&
                  registerFormTwoControl.digitOne?.dirty &&
                  !registerFormTwoControl.digitOne?.invalid &&
                  registerFormTwoControl.digitTwo?.dirty &&
                  !registerFormTwoControl.digitTwo?.invalid &&
                  registerFormTwoControl.digitFour?.dirty &&
                  !registerFormTwoControl.digitFour?.invalid &&
                  registerFormTwoControl.digitFive?.dirty &&
                  !registerFormTwoControl.digitFive?.invalid &&
                  !otpResend &&
                  serverErrorMessage
                "
              >
                {{ serverErrorMessage }}
              </p>
              <p class="text-center text-red pb-0 mb-0" *ngIf="otpResend">
                <span class="text-white m-0 p-0"
                  >OTP resend successfully !!</span
                >
              </p>

              <div class="col-md-12">
                <button
                  type="submit"
                  class="btn primary-xl mt-2"
                  [disabled]="isSubmitFormLoading"
                >
                  Confirm
                </button>
              </div>
            </fieldset>
          </form>
          <span class="bottom-text">
            <label class="login_cndtn redirec_sigin mt-4">
              <h6>
                Didn't receive the code?
                <a
                  class=" "
                  [ngClass]="otpTimeout == false ? 'disabled' : ''"
                  [ngClass]="otpTimeout == true ? 'green' : 'link-secondary'"
                  (click)="resendOTP()"
                  [ngStyle]="otpTimeout == true ? { cursor: 'pointer' } : ''"
                  >Resend</a
                >
              </h6>
              <h6 class="pt-2" *ngIf="counter > 0">
                <a href="javascript:void(0)" style="cursor: auto"
                  >{{ counter }} sec</a
                >
              </h6>
            </label>
          </span>
        </div>
      </div>
    </div>
  </div>
</section>
