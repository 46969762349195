import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {
  memberInviteEventEmitter = new EventEmitter<boolean>();
  addTeamEventEmitter = new EventEmitter<boolean>();
  TeamEventEmitter = new EventEmitter();
  addForceUpadate = new EventEmitter();
  colleagueEventEmitter = new EventEmitter();
  messagePostEventEmitter = new EventEmitter();
  listMessagePost = new EventEmitter();
  userAttendenceModalEventEmitter = new EventEmitter<boolean>();
  selfAttendenceModalEventEmitter = new EventEmitter<boolean>();
  collabTypeEventEmitter = new EventEmitter<boolean>();
  collabTypeTeamEventEmitter = new EventEmitter<boolean>();
  teamToColleagueEventEmitter = new EventEmitter<boolean>();
  colleagueToTeamEventEmitter = new EventEmitter<boolean>();
  colleagueSnap = new EventEmitter<boolean>();
  bckCollabEventEmitter = new EventEmitter();
  colleagueInviteEventEmitter = new EventEmitter<boolean>();

  homeEmitter = new EventEmitter<boolean>();
  imageCroppEventEmitter = new EventEmitter<boolean>();
  imageCropSaveEventEmitter = new EventEmitter<boolean>();
  sideBarEventEmitter = new EventEmitter<boolean>();
  imageuploadPopupEventEmitter = new EventEmitter();
  memberInvCloseEventEmitter = new EventEmitter();
  colleagueInvCloseEventEmitter = new EventEmitter();
  getLinkEventEmitter = new EventEmitter<boolean>();
  colleagueSnap2 = new EventEmitter<boolean>();
  singleMapModalEventEmitter = new EventEmitter<boolean>();
  attEventEventEmitter = new EventEmitter<boolean>();
  bckCollabCollegueEventEmitter = new EventEmitter();
  arrivalMessageEventEmitter = new EventEmitter<boolean>();
  InvEventEmitter = new EventEmitter<boolean>();
  InvEventEmitter2 = new EventEmitter<boolean>();
  LoginEventEmitter = new EventEmitter<boolean>();
  TeamFilterEmitter = new EventEmitter<boolean>();
  InviteMemberEmitter = new EventEmitter<boolean>();
  allColabSelfEmitter = new EventEmitter<boolean>();
  autoRefreshEmitter = new EventEmitter<boolean>();
  popupToAutocompleteEmitter = new EventEmitter<boolean>();
  datesEmitter = new EventEmitter<boolean>();
  addTmPopEmitter = new EventEmitter<boolean>();
  headerEmitter = new EventEmitter<boolean>();
  sortallColablist = new EventEmitter<boolean>();
  bulkUploadEmitter = new EventEmitter<boolean>();
  internalToTeamCard = new EventEmitter<boolean>();
  orgSetupComplete = new EventEmitter<boolean>();
  reportLoaderEmitter = new EventEmitter<boolean>();
  reportLoaderClose = new EventEmitter<boolean>();
  downloadReportSuccess = new EventEmitter<boolean>();
  downloadTeamDaysReport = new EventEmitter<boolean>();
  downloadTeamDaysReport1 = new EventEmitter<boolean>();
  reportButtonOk = new EventEmitter<boolean>();
  reportInternalCloseEmitter = new EventEmitter<boolean>();
  downloadTeamDaysReportInternal = new EventEmitter<boolean>();
  downloadTeamDaysInternalPop = new EventEmitter<boolean>();
  userAssociatedTeamEmitter = new EventEmitter<boolean>();
  drEmitter = new EventEmitter<boolean>();
  deactivatePopupEmitter = new EventEmitter<boolean>();
  removeHandlerEmitter = new EventEmitter<boolean>();
  fwdMsgEmitter = new EventEmitter<boolean>();
  fwdCloseEmitter = new EventEmitter<boolean>();
  fwdMsgImgEmitter = new EventEmitter<boolean>();
  fwdPopcrossEmitter = new EventEmitter<boolean>();
  fwdPopcrossPrevEmitter = new EventEmitter<boolean>();
  fwdChildFrmRefreshEnitter = new EventEmitter<boolean>();
  fwdMsgImgEmitterNegate = new EventEmitter<boolean>();
  maxFileSelectedEmitter = new EventEmitter<boolean>();
  colleagueUnreadMsgCount = new EventEmitter<boolean>();

  colleagueListReload = new EventEmitter<boolean>();
  teamListReload = new EventEmitter<boolean>();
  myFileListEmitter = new EventEmitter<boolean>();
  showMoreDoc = new EventEmitter<string>();
  showMoreLink = new EventEmitter<string>();
  showMoreImages = new EventEmitter<string>();
  showMoreDocInternal = new EventEmitter<string>();
  showMoreLinkInternal = new EventEmitter<string>();
  showMoreImageInternal = new EventEmitter<string>();
  reloadInternalAssociatedFile = new EventEmitter<boolean>();
  announncementFilterEmitter = new EventEmitter<boolean>();
  addRegionEventEmitter = new EventEmitter<boolean>();
  refreshRegionEventEmitter = new EventEmitter<boolean>();
  reportTeamFilter = new EventEmitter<string>();
  profileRegionEventEmitter = new EventEmitter<boolean>();
  editRegionEventEmitter = new EventEmitter<boolean>();

  constructor() {}

  emitAddTeamEvent() {
    this.TeamEventEmitter.emit();
  }
  emitForceUpdate() {
    this.addForceUpadate.emit();
  }
  emitColleagueList() {
    this.colleagueEventEmitter.emit();
  }
  emitMessageList() {
    this.messagePostEventEmitter.emit();
  }
  emitPostList() {
    this.listMessagePost.emit();
  }

  emitBckCollab() {
    this.bckCollabEventEmitter.emit();
  }
  emitProfileImageUpload() {
    this.imageuploadPopupEventEmitter.emit();
  }
  emitBckCollabCollegue() {
    this.bckCollabCollegueEventEmitter.emit();
  }
  emitRemoveHandler() {
    this.removeHandlerEmitter.emit();
  }
}
