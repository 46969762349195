import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  constructor() {}

  private fullName = new BehaviorSubject<string>('default message');
  currentMessage = this.fullName.asObservable();

  changeMessage(message: string) {
    this.fullName.next(message);
  }

  getFullNameComm() {
    if (localStorage.getItem('fullname') === null) return;
    let full =
      localStorage.getItem('current_fname').charAt(0).toUpperCase() +
      localStorage.getItem('current_fname').slice(1) +
      ' ' +
      localStorage.getItem('current_lname').charAt(0).toUpperCase() +
      localStorage.getItem('current_lname').slice(1);
    let nameArr = full.split(' ');

    for (let i = 0; i < nameArr.length; i++) {
      nameArr[i] = nameArr[i].charAt(0).toUpperCase() + nameArr[i].slice(1);
    }

    return full;
  }

  isMySpace$ = new Subject<boolean>();
  isTeamOrMember$ = new Subject<boolean>();
  changeTeamMember$ = new Subject<boolean>();
  newTeamCreated$ = new Subject<boolean>();
  allCollabEmptyCollab$ = new Subject<boolean>();
  attendance$ = new Subject();
  loadMoreMessages$ = new Subject();
  loadAllColabMessages$ = new Subject();
  connectSuperGroup$ = new Subject();
  loadNewMessageCount$ = new Subject();
  loadColleagueNewMessageCount$ = new Subject();
  loadNewAnnouncementMessageCount$ = new Subject();
  updateLatestByFindToken$ = new Subject();
  searchUser$ = new Subject();
}
