import SendbirdChat, { BaseChannel, UserUpdateParams } from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelHandler,
  GroupChannelListOrder,
  GroupChannelListQuery,
  GroupChannelListQueryParams,
  GroupChannelModule,
  MyMemberStateFilter,
  SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import { SendbirdChatParams, ChannelType } from '@sendbird/chat';
import {
  BaseMessage,
  FileMessageCreateParams,
  MessageListParams,
  PreviousMessageListQueryParams,
  UserMessage,
  UserMessageUpdateParams,
  MessageMetaArray,
  MessageRetrievalParams,
  MessageSearchQueryParams,
  MessageSearchOrder,
} from '@sendbird/chat/message';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../templates/authorized/chat-board/error-dialog.component';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SendbirdCustomService {
  userId = localStorage.getItem('user_id');
  app_id = localStorage.getItem('application_id');
  sb: SendbirdGroupChat;
  sbcurrentgroupobj: any;
  constructor(private dialog: MatDialog) {
    // base class is SendbirdChat, extends to SendbirdOpenChat | SendbirdGroupChat
  }

  sbConnect = () => {
    return new Promise(async (resolve, reject) => {
    if (!this.userId) {
      resolve('UserID is required.');
      return;
    }
	this.userId = localStorage.getItem('user_id');
	this.app_id = localStorage.getItem('application_id');
	const param: SendbirdChatParams<[GroupChannelModule]> = {
      appId: this.app_id,
      modules: [new GroupChannelModule()],
      localCacheEnabled: false,
    };
    this.sb = SendbirdChat.init(param) as SendbirdGroupChat;
    const user = this.sb.connect(this.userId)
      .then(() => {
		//this.registerPushNotificationTokenWithSendbird();
        resolve(user);
      })
      .catch(() => {
        resolve('SendBird Login Failed.');
      });
	});
  };

  // Sendbird sbCreateChannelWithUserIds
  sbCreateChannelWithUserIds = (userIds = [], toggle) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.sb.connect(this.userId);
        this.sb.groupChannel
          .createChannelWithUserIds(userIds, toggle)
          .then((e) => {
            resolve(e);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  //Disconnect SendBird
  sbDisconnect = () => {
    return new Promise((resolve, reject) => {
      this.sb
        .disconnect()
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(true);
        })
        .finally(() => {
          resolve(true);
        });
    });
  };

  //Group Channel Methods
  sbCreateGroupChannelListQuery = () => {
    return this.sb.groupChannel.createMyGroupChannelListQuery();
  };

  getGroupChannels(): Promise<GroupChannel[]> {
    const params: GroupChannelListQueryParams = {
      includeEmpty: true,
      myMemberStateFilter: MyMemberStateFilter.JOINED,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      limit: 15,
    };
    const listQuery: GroupChannelListQuery =
      this.sb.groupChannel.createMyGroupChannelListQuery(
        params
      ) as GroupChannelListQuery;
    return new Promise((resolve, reject) => {
      if (listQuery.hasNext) {
        listQuery
          .next()
          .then((channels: GroupChannel[]) => {
            resolve(channels);
          })
          .catch((error: any) => {
            reject(error);
          });
      } else {
        resolve([]);
      }
    });
  }

  async getGroupChannelsWithQuery(query): Promise<GroupChannel[]> {
    try {
      const user = await this.sb.connect(this.userId);
      const listQuery = query;
      return new Promise((resolve, reject) => {
        if (listQuery.hasNext) {
          listQuery
            .next()
            .then((channels: GroupChannel[]) => {
              resolve(channels);
            })
            .catch((error: any) => {
              reject(error);
            });
        } else {
          resolve([]);
        }
      });
    } catch {}
  }

  sbGetGroupChannel = (channelUrl) => {
    return new Promise(async (resolve, reject) => {
      try {
        const channel = await this.sb.groupChannel.getChannel(channelUrl);
        if (channel !== null) {
          this.sbcurrentgroupobj = channel;
          resolve(channel);
        } else {
          // this.openErrorDialog('Channel not found');
          reject(new Error('Channel not found'));
        }
      } catch (error) {
        console.error('Error fetching group channel:', error);
        if (error.message.includes('not found')) {
          //this.openErrorDialog('The requested Team is not found.');
          console.error('The requested Team is not found.', error);
        } else if (
          error.message.includes('Not authorized. "User must be a member.".')
        ) {
          //this.openErrorDialog('You are not a part of this team any more');
          console.error('Not authorized. "User must be a member."', error);
        } else {
          // this.openErrorDialog('Unknown Error from the Sendbird');
        }
        reject(error);
      }
    });
  };

  modalDialog: any;
  openErrorDialog(errorMessage: string): void {
    localStorage.removeItem('channel_url');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '50vw';
    dialogConfig.panelClass = 'borderless-dialog';
    dialogConfig.panelClass = 'bck-remove';
    dialogConfig.data = errorMessage;
    this.modalDialog = this.dialog.open(ErrorDialogComponent, dialogConfig);
  }
  closeOrgDeactivate() {
    this.modalDialog.close();
  }

  sbGetGroupChannelOld = (channelUrl) => {
    return new Promise(async (resolve, reject) => {
      const channel = await this.sb.groupChannel.getChannel(channelUrl);

      if (channel) {
        resolve(channel);
      } else {
        reject();
      }
    });
  };

  sbCreatePreviousMessageListQuery = (
    channelUrl: string,
    channel: GroupChannel
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.sbGetGroupChannel(channelUrl)
        .then((channel: any) => {
          //this.sbcurrentgroupobj = channel;
          const params: PreviousMessageListQueryParams = {
            includeReactions: true,
            includeMetaArray: true,
          };
          const query = channel.createPreviousMessageListQuery(params);
          resolve(query);
        })
        .catch((error) => reject(error));
    });
  };

  sbGetMessageList = (previousMessageListQuery) => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await this.sb.connect(this.userId);
        previousMessageListQuery.load().then((e) => {
          resolve(e);
        });
      } catch (err) {
        reject('SendBird Login Failed.');
      }
    });
  };

  sendUserMessage = (channel, params): Promise<any> => {
    return new Promise((resolve, reject) => {
      channel
        .sendUserMessage(params)
        .onSucceeded((message) => {
          // A text message is successfully sent to the channel.

          resolve({ status: 'success', message });
        })
        .onFailed((error) => {
          // Failed to send the text message to the channel.
          console.error(error);
          reject({ status: 'error', error });
        });
    });
  };

  // Updating the Text Message.
  updateUserMessage = (
    messageId,
    channel,
    params: UserMessageUpdateParams
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      channel
        .updateUserMessage(messageId, params)
        .then((updatedMessage) => {
          // The message has been successfully updated
          resolve(updatedMessage);
          //
        })
        .catch((error) => {
          // An error occurred while updating the message
          reject(error);
          console.error('Error updating message:', error);
        });
    });
  };

  // Sending the Messages with Attachements

  sendFileMessage = (
    channel: GroupChannel,
    params: FileMessageCreateParams
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      channel
        .sendFileMessage(params)
        .onSucceeded((message) => {
          // A text message is successfully sent to the channel.

          resolve({ status: 'success', message });
        })
        .onFailed((error) => {
          // Failed to send the text message to the channel.
          console.error(error);
          reject({ status: 'error', error });
        });
    });
  };

  updateFileMessage = (
    channel: GroupChannel,
    params: FileMessageCreateParams,
    messageID: any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      channel
        .updateFileMessage(messageID, params)
        .then((message) => {
          // A text message is successfully sent to the channel.

          resolve({ status: 'success', message });
        })
        .catch((error) => {
          // Failed to send the text message to the channel.
          console.error(error);
          reject({ status: 'error', error });
        });
    });
  };

  loadMoreMessages(
    channel: GroupChannel,
    messageId: number,
    listQuery: MessageListParams
  ): Promise<BaseMessage[]> {
    return new Promise(async (resolve, reject) => {
      try {
        // Get the message with the provided messageId
        await channel
          .getMessagesByMessageId(messageId, listQuery)
          .then((messages) => {
            resolve(messages);
          })
          .catch((e) => {
            reject(e);
          });
      } catch (err) {
        reject('Error loading more messages.');
      }
    });
  }

  sbMarkAsRead = (channelUrl, channel) => {
    if (channel) {
      if (channel?.unreadMessageCount) {
        channel.markAsRead();
      }
    } else {
      this.sbGetGroupChannel(channelUrl).then((channel: any) =>
        channel.markAsRead()
      );
    }
  };

  sbPinMessage = (channel, messageId) => {
    return new Promise(async (resolve, reject) => {
      await channel
        .pinMessage(messageId)
        .then((message) => {
          // A text message is successfully sent to the channel.

          resolve({ status: 'success', message });
        })
        .catch((error) => {
          // Failed to send the text message to the channel.
          console.error(error);
          reject({ status: 'error', error });
        });
    });
  };

  sbUnpinMessage = (channel, messageId) => {
    return new Promise(async (resolve, reject) => {
      await channel
        .unpinMessage(messageId)
        .then((message) => {
          // A text message is successfully sent to the channel.

          resolve({ status: 'success', message });
        })
        .catch((error) => {
          // Failed to send the text message to the channel.
          console.error(error);
          reject({ status: 'error', error });
        });
    });
  };

  getPinMessageStatus = (channelUrl, messageId) => {
    return new Promise(async (resolve, reject) => {
      const params: MessageRetrievalParams = {
        messageId: messageId,
        channelType: ChannelType.GROUP,
        channelUrl: channelUrl,
        includeReactions: true,
        includeMetaArray: true,
      };

      this.sb.message
        .getMessage(params)
        .then((message) => {
          resolve({ status: 'success', message });
        })
        .catch((error) => {
          // Failed to send the text message to the channel.
          console.error(error);
          resolve({ status: 'error', error });
        });
    });
  };

  searchMessages(channel: GroupChannel, keyword: string): Promise<any> {
    const params: MessageSearchQueryParams = {
      keyword: keyword,
      channelUrl: channel?.url,
      //channelCustomType: '',
      limit: 99,
      exactMatch: false,
      messageTimestampFrom: channel?.joinedAt * 1000,
      messageTimestampTo: null,
      order: MessageSearchOrder.TIMESTAMP,
      reverse: false,
    };
    console.log('connectionObj:', this.sb);
    const searchQuery = this.sb.createMessageSearchQuery(params);
    console.log('searchQuery:', searchQuery);
    return new Promise((resolve, reject) => {
      if (searchQuery.hasNext) {
        searchQuery
          .next()
          .then((message) => {
            resolve({ status: 'success', message });
          })
          .catch((error: any) => {
            reject(error);
          });
      } else {
        resolve([]);
      }
    });
  }

  async registerPushNotificationTokenWithSendbird() {
    return new Promise(async (resolve, reject) => {
      const token = localStorage.getItem('push-notification-token');
      await this.sb
        .registerFCMPushTokenForCurrentUser(token)
        .then((message) => {
          resolve({ status: 'success', message });
        })
        .catch((error) => {
          // Failed to send the text message to the channel.
          console.error(error);
          reject({ status: 'error', error });
        });
    });
  }
}
