import { Component, OnInit,HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsoleToggleService } from './service/console.log.service';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { UtilsService } from 'src/app/service/utils.service';
import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'] 
})
export class AppComponent implements OnInit {
	title = 'Redium';
	message:any = null;
	notification: boolean = false;
	all_users: any;
	profileImage:string;
  
	constructor(private router: Router, 
	private consoleToggleService: ConsoleToggleService,
	private UtilsService: UtilsService,
	){
      this.consoleToggleService.disableConsoleInProduction();
    }
	ngOnInit() {
	
	//this.requestPermission();
    //this.listen();
	
	let formChanged = true;
		
    window.addEventListener('storage', (event) => {
        if (event.storageArea == localStorage) {
             let token = localStorage.getItem('fullname');
             if(token == undefined) {
               // Perform logout
               //Navigate to login/home
                this.router.navigate(['/login']);
             }
        }
    });
	}
	
	requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
      if (currentToken) {
        console.log("token.....")
		localStorage.setItem('push-notification-token',currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
	  this.all_users = JSON.parse(localStorage.getItem('all_users'));
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
	}
  
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
     this.message= payload?.data?JSON.parse(payload?.data.sendbird):null;
     let filterData = this.all_users.filter(
            (el) => el.sendbird_user_details.user_id === this.message.sender.id
          );
     this.profileImage = (filterData && filterData.length>0)?filterData[0].userpicture:null;
	 this.notification = true;
	 setTimeout(() => {
        this.notification = false;
      }, 8000);      
    });
	
  }
  
  emptyCollab() {
	this.notification = false;
    localStorage.setItem('allCollab', 'true');
    this.router.navigate(['dashboard/members-collab']);
  }
}
