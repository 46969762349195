import { Injectable } from '@angular/core';
import { AuthHttpService } from 'src/app/service/auth-http.service';
import { ReplaySubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionDataService {
  private PERMISSION: string = 'permission';
  private _permission: any;
  private _permissionHandler: ReplaySubject<PermissionInterface> =
    new ReplaySubject<PermissionInterface>();
  private _currentRole: string = '';

  constructor(private http: AuthHttpService) {}

  getPermissionMatrixFromHttp(): void {
    // this.http.getPermissionMatrix().subscribe((response) => {
    //   if (response.success) {
    //     this._permission = response.data;
    //     localStorage.setItem(this.PERMISSION, JSON.stringify(this._permission));
    //     this._permissionHandler.next(this._permission);
    //   }
    // });
    if (localStorage.getItem('current-role') != null) {
      localStorage.setItem('current-role', localStorage.getItem('user-role'));
      //this._currentRole = localStorage.getItem('current-role');
    }
    if (this._currentRole !== localStorage.getItem('user-role')) {
      if (localStorage.getItem('user-role') == 'SUPERADMIN') {
        //alert('aaa');
        this.permissionAdmin();
      } else if (localStorage.getItem('user-role') == 'RegionAdmin') {
        this.permissionRegionAdmin();
      } else if (localStorage.getItem('user-role') == 'TeamAdmin') {
        // alert('bbb');
        this.permissionTeamAdmin();
      } else {
        //alert('cccc');
        this.permissionMember();
      }
      localStorage.setItem('current-role', localStorage.getItem('user-role'));
    }
  }
  permissionRegionAdmin() {
    this._permission = {
      Teams: {
        Create: true,
        View: true,
        Edit: true,
        Delete: true,
        Member: {
          Create: true,
          View: true,
          Edit: true,
          Delete: true,
          Invite_Existing_Member: true,
          Invite_External_New_Member: true,
        },
        Documents: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
          Download: true,
        },
        Links: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
        },
        Images: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
          Download: true,
        },
        EditMyTeam: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
        },
        Search: true,
      },
      Members: {
        Create: true,
        View: true,
        Edit: true,
        Delete: true,
        Allow_ToSet_TeamAdmin: true,
        Search: true,
        Customize: true,
        Bulk_Import: true,
      },
      Announcement: {
        Invoke: true,
      },
      Region: {
        Create: true,
        View: true,
        Edit: true,
        Delete: true,
      },
    };
  }
  permissionAdmin() {
    this._permission = {
      Teams: {
        Create: true,
        View: true,
        Edit: true,
        Delete: true,
        Member: {
          Create: true,
          View: true,
          Edit: true,
          Delete: true,
          Invite_Existing_Member: true,
          Invite_External_New_Member: true,
        },
        Documents: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
          Download: true,
        },
        Links: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
        },
        Images: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
          Download: true,
        },
        EditMyTeam: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
        },
        Search: true,
      },
      Members: {
        Create: true,
        View: true,
        Edit: true,
        Delete: true,
        Allow_ToSet_TeamAdmin: true,
        Search: true,
        Customize: true,
        Bulk_Import: true,
      },
      Announcement: {
        Invoke: true,
      },
    };
  }

  permissionTeamAdmin() {
    this._permission = {
      Teams: {
        Create: true,
        View: true,
        Edit: false,
        Delete: true,
        Member: {
          Create: true,
          View: true,
          Edit: true,
          Delete: true,
          Invite_Existing_Member: true,
          Invite_External_New_Member: false,
        },
        Documents: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
          Download: true,
        },
        Links: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
        },
        Images: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
          Download: true,
        },
        EditMyTeam: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
        },
        Search: false,
      },
      Members: {
        Create: false,
        View: false,
        Edit: false,
        Delete: false,
        Allow_ToSet_TeamAdmin: false,
        Search: false,
        Customize: false,
        Bulk_Import: false,
      },
      Announcement: {
        Invoke: false,
      },
    };
  }

  permissionMember() {
    this._permission = {
      Teams: {
        Create: false,
        View: false,
        Edit: false,
        Delete: false,
        Member: {
          Create: false,
          View: false,
          Edit: false,
          Delete: false,
          Invite_Existing_Member: false,
          Invite_External_New_Member: false,
        },
        Documents: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
          Download: true,
        },
        Links: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
        },
        Images: {
          Create: true,
          Delete: true,
          Update: true,
          View: true,
          Download: true,
        },
        EditMyTeam: {
          Create: false,
          Delete: false,
          Update: false,
          View: false,
        },
        Search: true,
      },
      Members: {
        Create: false,
        View: false,
        Edit: false,
        Delete: false,
        Allow_ToSet_TeamAdmin: false,
        Search: false,
        Customize: false,
        Bulk_Import: false,
      },
      Announcement: {
        Invoke: false,
      },
    };
  }

  get configHandler(): Observable<PermissionInterface> {
    return this._permissionHandler.asObservable();
  }

  get config(): PermissionInterface {
    if (!this._permission) {
      //  check in local
      if (localStorage.getItem(this.PERMISSION))
        return JSON.parse(localStorage.getItem(this.PERMISSION));
      //  get from api
      else this.getPermissionMatrixFromHttp();
    }

    return this._permission;
  }
}

export interface PermissionInterface {
  Teams: {
    Create: boolean;
    View: boolean;
    Edit: boolean;
    Delete: boolean;
    Member: {
      Create: boolean;
      View: boolean;
      Edit: boolean;
      Delete: boolean;
      Invite_Existing_Member: boolean;
      Invite_External_New_Member: boolean;
    };
    Documents: {
      Create: boolean;
      View: boolean;
      Edit: boolean;
      Delete: boolean;
      Download: boolean;
    };
    EditMyTeam: {
      Create: boolean;
      View: boolean;
      Update: boolean;
      Delete: boolean;
    };
    Links: {
      Create: boolean;
      View: boolean;
      Edit: boolean;
      Delete: boolean;
    };
    Images: {
      Create: boolean;
      View: boolean;
      Edit: boolean;
      Delete: boolean;
    };
    Search: boolean;
  };
  Members: {
    Create: boolean;
    View: boolean;
    Edit: boolean;
    Delete: boolean;
    Allow_ToSet_TeamAdmin: boolean;
    Search: boolean;
    Customize: boolean;
    Bulk_Import: boolean;
  };
  Announcement: {
    Invoke: boolean;
  };
}
